<template>
  <div class="bgapp d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Confirmation</h1>
                <p class="text-muted">
                  Please input confirmation code in your email inbox/spam.
                </p>
                <CRow>
                  <CCol col="3" class="text-center">
                    <CInput placeholder=" " :maxlength="max" v-model="c1" class="text-center"/>
                  </CCol>
                  <CCol col="3" class="text-left">  
                    <CInput placeholder=" " :maxlength="max" v-model="c2" class="text-center"/>
                  </CCol>
                  <CCol col="3" class="text-left">
                    <CInput placeholder=" " :maxlength="max" v-model="c3" class="text-center"/>
                  </CCol>
                  <CCol col="3" class="text-left">
                    <CInput placeholder=" " :maxlength="max" v-model="c4" class="text-center"/>
                  </CCol>
                </CRow>
                <CRow>
                    <CCol col="5" class="text-left">
                     <CButton color="link" to="/login" class="px-0"
                        >Just Login!</CButton>
                    </CCol>
                    <CCol col="7" class="text-right">
                      
                      <CButton color="success" v-on:click="confirmNow" 
                        >Verify Code</CButton>
                    </CCol>
                  </CRow>
              </CForm>
            </CCardBody>
            <!-- <CCardFooter class="p-4">
              <CRow>
                <CCol col="6">
                  <CButton block color="facebook"> Facebook </CButton>
                </CCol>
                <CCol col="6">
                  <CButton block color="twitter"> Twitter </CButton>
                </CCol>
              </CRow>
            </CCardFooter> -->
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <CModal title="Warning" :show.sync="myModal2" size="sm">
      Failed! Confirmation failed, please check the code in your  email inbox.
    </CModal>
    <CModal title="Warning" :show.sync="myModal" size="sm">
      Failed! Please fill your confirmation code.
    </CModal>
    <CModal title="Sukses" color="success" :show.sync="mySukses" size="sm">
      Success! Account has been verified. You can login to your account now
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Confirmation",
  data() {
    return {
      tabs: [],
      myModal: false,
      myModal2: false,
      mySukses: false,
      users: "",
      dismissCountDown: 0,
      country: "",
      nama: "",
      password: "",
      repassword: "",
      email: "",
      telpon: "",
      rcvr: "",
      c1:"",
      c2:"",
      c3:"",
      c4:"",
      confirmation :"",
      activeTab: 1,
      max : 1,
    };
  },
  // mounted(){
  // // this.showUser();
  // },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    confirmNow: function (event) {
      let userdata = JSON.parse(localStorage.getItem('user'));
      // Simple POST request with a JSON body using axios
      const user = {
        confirmation: this.c1+this.c2+this.c3+this.c4,
        email: this.$route.params.mail,
      };
      // alert(localStorage.getItem("jwt"));
      if(this.c1 == null || this.c1 == "",
          this.c2 == null || this.c2 == "",
          this.c3 == null || this.c3 == "",
          this.c4 == null || this.c4 == "")
      {
          this.myModal = true;
      }
      else{
          axios
            .post(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/confirmapp/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              user
            )
            .then((response) => {
              // console.log(response);
              // alert(response.data);
              // this.tabs = response.data;/
              if (response.data == "sukses") {
                
                this.mySukses = true;
                if(this.mySukses==false){
                  this.$router.push("/login");  
                }
                

                // this.myModal2 = true;
              } else {
                // alert(JSON.stringify(user));
                this.myModal2 = true;
              }
            });
      }
        
    },
  },
};
</script>
